import { TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Paper, Stack } from "@mui/material";
import { BookabilityDecision, BookabilityUnmetCriteria } from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { Button } from "@src/appV2/redesign/components/Button";
import { SHIFT_DISCOVERY_ON_CALL_SHIFT_REGISTERED_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { timeSlotToShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { MarkInterestButton } from "../MarkInterestButton";
import { ConfirmRegisterOnCallShiftSheet } from "./ConfirmRegisterOnCallShiftSheet";
import { OnCallLearnMore } from "./LearnMore";
import { RegisterOnCallShiftButton } from "./RegisterOnCallShiftButton";
import { OnCallShiftPayIndicator } from "./ShiftPayIndicator";

export function OnCallShiftBottomSheetFooter() {
  const { navigateToModal, shift, bookabilityStatus, isLoadingShiftModalsData, hasMarkedInterest } =
    useShiftModalsDataContext();

  const { workplace } = shift ?? {};

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED &&
    isDefined(shift?.offer);

  const isTestAccount = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_DISABLED_TEST_ACCOUNT,
  ]);

  const isInstantBookDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
  ]);

  const shouldHideBookCta = isInstantBookDisabled || isTestAccount;

  const confirmRegisterModalState = useModalState();

  const isInstantPay = false;

  return (
    <Paper elevation={2}>
      <Stack
        p={5}
        pb={8}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        spacing={7}
      >
        <OnCallLearnMore
          workplaceId={workplace?.id}
          shiftPeriod={timeSlotToShiftPeriod[shift?.attributes.timeSlot ?? TimeSlot.PM]}
        />
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box width="100%" pl={5}>
            <OnCallShiftPayIndicator
              totalPay={shift?.offer?.attributes.formattedTotalPay}
              hourlyPay={shift?.offer?.attributes.formattedHourlyPay}
              isInstantPay={isInstantPay}
              isLoading={isLoadingShiftModalsData}
            />
          </Box>
          {isTestAccount && (
            <Button fullWidth variant="contained" size="large">
              Disabled for test accounts
            </Button>
          )}
          {isInstantBookDisabled && !hasMarkedInterest && isDefined(shift?.id) && (
            <MarkInterestButton
              shiftId={shift.id}
              workplaceId={workplace?.id ?? ""}
              offerId={shift.offer?.id}
            />
          )}
          {isDefined(shift) &&
            isDefined(workplace) &&
            isDefined(shift.offer) &&
            !shouldHideBookCta && (
              <>
                <RegisterOnCallShiftButton
                  shiftId={shift.id}
                  shiftOfferId={shift.offer.id}
                  workplaceId={workplace.id}
                  disabled={!isBookable}
                  onClick={confirmRegisterModalState.openModal}
                />
                <ConfirmRegisterOnCallShiftSheet
                  modalState={confirmRegisterModalState}
                  shift={shift}
                  workplace={workplace}
                  onConfirm={() => {
                    navigateToModal(SHIFT_DISCOVERY_ON_CALL_SHIFT_REGISTERED_MODAL_PATH, {
                      shiftId: shift.id,
                    });
                  }}
                />
              </>
            )}
        </Stack>
      </Stack>
    </Paper>
  );
}
