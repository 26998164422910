import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Button } from "@src/appV2/redesign/components/Button";

interface RegisterOnCallShiftButtonProps {
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  disabled?: boolean;
  onClick: () => void;
}

export function RegisterOnCallShiftButton(props: RegisterOnCallShiftButtonProps) {
  const { onClick, shiftId, shiftOfferId, workplaceId, disabled } = props;

  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      disabled={disabled}
      onClick={async () => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_REGISTER_ON_CALL_SHIFT, {
          shiftId,
          workplaceId,
          shiftOfferId,
        });

        onClick();
      }}
    >
      {disabled ? "Can't book" : "Book"}
    </Button>
  );
}
