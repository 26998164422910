import { Text, useModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

import { LearnMoreSheet } from "./LearnMoreSheet";

export const ON_CALL_TIME_SLOT_GRADIENT_MAP: Record<ShiftPeriod, string> = {
  [ShiftPeriod.AM]:
    "radial-gradient(119.9% 100% at 0% 0%, #FFDBA6 10%, #F6E6CF 59.9%, #D7EFFC 100%)",
  [ShiftPeriod.PM]:
    "radial-gradient(112.18% 113.01% at 100% 100%, #FBD0A8 0%, #F8B4B5 56.12%, #9AA2FA 100%)",
  [ShiftPeriod.NOC]:
    "radial-gradient(96.11% 91.5% at 0% 8.5%, #BAC9F3 0%, #849DF0 66.86%, #5C6EB4 100%)",
};

interface OnCallLearnMoreProps {
  shiftPeriod: ShiftPeriod;
  workplaceId?: string;
}

export function OnCallLearnMore(props: OnCallLearnMoreProps) {
  const { workplaceId, shiftPeriod } = props;

  const modalState = useModalState();

  const borderGradient = ON_CALL_TIME_SLOT_GRADIENT_MAP[shiftPeriod];

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      px={6}
      py={4}
      sx={(theme) => ({
        borderRadius: theme.borderRadius?.small ?? "inherit",
        border: "4px solid transparent",
        background: `linear-gradient(${theme.palette.background.quaternary}, ${theme.palette.background.quaternary}) padding-box, ${borderGradient} border-box`,
      })}
    >
      <Text>This is an on-call shift</Text>
      <Button variant="outlined" size="small" onClick={modalState.openModal}>
        Learn more
      </Button>
      <LearnMoreSheet modalState={modalState} workplaceId={workplaceId} />
    </Stack>
  );
}
