import { BottomSheet, CbhIcon, List, ListItem } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { useOnCallShiftsConfig } from "@src/appV2/Shifts/Shift/useOnCallShiftsConfig";

import { OnCallBottomSheetCloseButton } from "./OnCallBottomSheetCloseButton";

function Bold(props: { children: React.ReactNode }) {
  return <strong style={{ fontWeight: 600, letterSpacing: "0.03em" }}>{props.children}</strong>;
}

interface LearnMoreSheetProps {
  modalState: UseModalState;
  workplaceId?: string;
}

export function LearnMoreSheet(props: LearnMoreSheetProps) {
  const { modalState, workplaceId } = props;

  const {
    noPlacementBonusPayHours: globalNoPlacementBonusPayHours,
    onCallAreas,
    offersDistanceInMiles,
  } = useOnCallShiftsConfig();
  const workplaceConfig = onCallAreas.find((area) => area.parentWorkplaceId === workplaceId);
  const noPlacementBonusPayHours =
    workplaceConfig?.noPlacementBonusPayHours ?? globalNoPlacementBonusPayHours;
  const distanceInMiles = workplaceConfig?.offersDistanceInMiles ?? offersDistanceInMiles;

  return (
    <BottomSheet modalState={modalState}>
      <Stack
        spacing={10}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        py={8}
        px={10}
      >
        <OnCallBottomSheetCloseButton onClick={modalState.closeModal} />
        <Stack alignItems="center" justifyContent="center" textAlign="center" spacing={5}>
          <Title bold component="h4" py={3}>
            After your on-call shift starts, we’ll find you a placement at a real facility.
          </Title>
          <Stack alignItems="left" justifyContent="center" spacing={5}>
            <List component="ol" sx={{ listStyle: "decimal" }}>
              <ListItem sx={{ display: "list-item", paddingTop: 0 }}>
                <Text variant="body1">
                  <Bold>Guaranteed earnings.</Bold> Earn {noPlacementBonusPayHours} hours of pay if
                  we can’t find you a real shift. If you get sent home or cancelled by the facility
                  we place you in, we’ll guarantee you get 8 hours.
                </Text>
              </ListItem>
              <ListItem sx={{ display: "list-item", paddingTop: 0 }}>
                <Text variant="body1">
                  <Bold>Stay at home when your on-call starts.</Bold> No need to go anywhere. Just
                  keep your phone turned on and your eyes on the Clipboard app.
                </Text>
              </ListItem>
              <ListItem sx={{ display: "list-item", paddingTop: 0 }}>
                <Text variant="body1">
                  <Bold>Drive up to {distanceInMiles} miles for your placement.</Bold> When you
                  register for on-call, you should be prepared to travel.
                </Text>
              </ListItem>
              <ListItem sx={{ display: "list-item", paddingTop: 0 }}>
                <Text variant="body1">
                  <Bold>Accept your placement to keep access.</Bold> If you cancel an on-call shift,
                  or don’t accept the placement we send you, you’ll lose ability to book on-call
                  shifts.
                </Text>
              </ListItem>
            </List>
          </Stack>
        </Stack>
        <Button variant="outlined" size="large" href="tel:+19732405483">
          <Stack direction="row" alignItems="center" spacing={3}>
            <CbhIcon type="phone" />
            <Text>Call support</Text>
          </Stack>
        </Button>
      </Stack>
    </BottomSheet>
  );
}
