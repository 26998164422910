import { TextSkeleton } from "@clipboard-health/ui-components";
import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import { useGetQualificationDisplayLabel } from "@src/appV2/Qualifications/api/useGetQualificationDisplayLabel";
import { minutesToMilliseconds, parseISO } from "date-fns";

import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { formatDayOfMonthWithDayName } from "../../utils/formatDayOfMonthWithDayName";
import { WorkTogetherStack } from "../../WorkWithFriends/components/WorkTogetherStack";
import { getGradientByTimeSlot } from "../getGradientByTimeSlot";
import { type ShiftSlotCoalesced } from "../Open/useGetShiftsSlotV2";
import { ShiftBottomSheetHeaderPill } from "./Pills";

interface ShiftBottomSheetHeaderProps {
  onClose: () => void;
  shiftSlots?: ShiftSlotCoalesced;
}

export function ShiftBottomSheetHeader(props: ShiftBottomSheetHeaderProps) {
  const { onClose, shiftSlots } = props;

  // Refresh this component every minute to get the latest priority status
  useCurrentDate(minutesToMilliseconds(1));

  const { shift, isLoadingShiftModalsData, shiftFailedLoading } = useShiftModalsDataContext();
  const { data: qualificationText, isLoading: isLoadingQualification } =
    useGetQualificationDisplayLabel(shift?.attributes.qualification ?? "");

  const showLoadingState = !isDefined(shift) && isLoadingShiftModalsData && !shiftFailedLoading;

  return (
    <Stack
      alignItems="space-between"
      sx={(theme) => ({
        paddingTop: 6,
        paddingBottom: 7,
        px: 5,
        transition: theme.transitions.create(["background"]),
        background: isDefined(shift?.attributes.timeSlot)
          ? getGradientByTimeSlot({ timeSlot: shift.attributes.timeSlot, theme })
          : undefined,
      })}
      spacing={4}
    >
      <PageHeaderTopRow
        sx={{ px: 0, paddingBottom: 3 }}
        rightAction={
          isDefined(shift) && <ShiftBottomSheetHeaderPill shiftType={shift.attributes.type} />
        }
        onClickBack={() => {
          logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shift?.id ?? "" });

          if (shiftFailedLoading) {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_UNAVAILABLE_CLOSED, {
              shiftId: shift?.id ?? "",
            });
          }

          onClose();
        }}
      />

      <Stack sx={{ paddingLeft: 3 }} spacing={4}>
        {showLoadingState || isLoadingQualification ? (
          <TextSkeleton variant="body1" width="20%" />
        ) : (
          <Text semibold variant="body1">
            {qualificationText}
          </Text>
        )}

        {showLoadingState && <TextSkeleton variant="h3" width="80%" />}

        <Stack direction="row" spacing={1} justifyContent="space-between">
          {!showLoadingState && isDefined(shift?.attributes.start) && (
            <Title variant="h3" component="h2">
              {formatDayOfMonthWithDayName(parseISO(shift.attributes.start), {
                formatDayString: "EEEE",
                timezone: shift.workplace.attributes.location.timezone,
              })}
            </Title>
          )}

          {isDefined(shiftSlots?.shiftAssignments) && (
            <WorkTogetherStack workers={shiftSlots.shiftAssignments.map(({ worker }) => worker)} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
