import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";

import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

interface ShiftBottomSheetNotInstantBookableCardProps {
  areBookingRequestsEnabled?: boolean;
}

export function ShiftBottomSheetNotInstantBookableCard(
  props: ShiftBottomSheetNotInstantBookableCardProps
) {
  const { areBookingRequestsEnabled = false } = props;

  const { shiftId } = useShiftModalsDataContext();

  useLogEffect(APP_V2_USER_EVENTS.SHIFT_DETAILS_NOT_INSTANT_BOOKABLE, {
    shiftId,
  });

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Request this shift"
      description={
        areBookingRequestsEnabled
          ? "This workplace must approve your request to book."
          : "A Clipboard representative will reach out as soon as you are approved."
      }
      illustrationType="chat"
    />
  );
}
