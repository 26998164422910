import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useMarkInterestShift } from "@src/appV2/OpenShifts/ShiftAction/api/useMarkInterestShift";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";

import { LoadingButton } from "../../components/LoadingButton";
import { invalidateShifts } from "../../invalidateShifts";
import { useCanWorkerSeeWorkplaceBookingRequests } from "../../WorkerShifts/BookingRequests/api/useCanWorkerSeeWorkplaceBookingRequests";
import { useCreateBookingRequest } from "../../WorkerShifts/BookingRequests/api/useCreateBookingRequest";
import { useGetBookingRequests } from "../../WorkerShifts/BookingRequests/api/useGetBookingRequests";
import { BookingRequestStatus } from "../../WorkerShifts/BookingRequests/types";

interface MarkInterestButtonProps {
  shiftId: string;
  workplaceId: string;
  offerId?: string;
}

export function MarkInterestButton(props: MarkInterestButtonProps) {
  const { shiftId, workplaceId, offerId } = props;
  const queryClient = useQueryClient();
  const workerId = useDefinedWorker().userId;
  const shouldShowBookingRequests = useCanWorkerSeeWorkplaceBookingRequests(workplaceId);
  const { data: bookingRequests, refetch: refetchBookingRequests } = useGetBookingRequests(
    {
      filter: {
        status: { eq: BookingRequestStatus.PENDING },
        "worker.id": { eq: workerId },
        "shift.id": { eq: shiftId },
      },
    },
    {
      enabled: shouldShowBookingRequests,
    }
  );

  const { mutate: markInterest, isLoading: isMarkingInterest } = useMarkInterestShift({
    onSuccess: async () => {
      logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_MARK_INTEREST, {
        shiftId,
      });

      await invalidateShifts(queryClient);
    },
    useErrorBoundary: false,
    meta: {
      userSuccessMessage: "Approval has been requested.",
      userErrorMessage: "Error requesting approval!",
    },
  });

  const { mutate: createBookingRequest, isLoading: isCreatingBookingRequest } =
    useCreateBookingRequest({
      onSuccess: async () => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_CREATE_BOOKING_REQUEST, {
          shiftId,
          offerId,
          workplaceId,
          workerId,
        });

        await invalidateShifts(queryClient);
        await refetchBookingRequests();
      },
      useErrorBoundary: false,
      meta: {
        userSuccessMessage: "Shift has been requested.",
        userErrorMessage: "Error requesting shift!",
      },
    });

  const isLoading = isMarkingInterest || isCreatingBookingRequest;

  const shiftHasAlreadyBeenRequested =
    shouldShowBookingRequests && (!offerId || (bookingRequests?.data?.length ?? 0) > 0);

  return (
    <LoadingButton
      size="large"
      variant="contained"
      isLoading={isLoading}
      disabled={shiftHasAlreadyBeenRequested}
      onClick={async () => {
        if (shouldShowBookingRequests && offerId) {
          createBookingRequest({ shiftId, offerId, workerId });
        } else {
          markInterest({ shift: shiftId });
        }
      }}
    >
      {shouldShowBookingRequests && shiftHasAlreadyBeenRequested && "Shift requested"}
      {shouldShowBookingRequests && !shiftHasAlreadyBeenRequested && "Request this shift"}
      {!shouldShowBookingRequests && "Request approval"}
    </LoadingButton>
  );
}
