import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { HourlyPayLabel } from "../../Pay/HourlyPayLabel";
import { TotalPayLabel } from "../../Pay/TotalPayLabel";
import { SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH } from "../../ShiftDiscovery/paths";
import { BookShiftButton } from "./BookShiftButton";
import { MarkInterestButton } from "./MarkInterestButton";
import { RateNegotiationAction } from "./RateNegotiationAction";
import { useFooterData } from "./useFooterData";

export function ShiftBottomSheetFooter() {
  const {
    isBookable,
    isTestAccount,
    isRateNegotiationEnabled,
    shouldHideBookCta,
    shiftId,
    workplace,
    offer,
    navigateToModal,
    shift,
    showMarkInterestButton,
  } = useFooterData();

  return (
    <DialogFooter withShadow orientation="vertical">
      {/* TODO: This stack layout will probably not be needed, depending on how metalab will place the rate negotiation button. So this is kind of an wip thing */}
      <Stack direction="row" sx={{ width: "100%", justifyContent: "space-between" }}>
        <Stack spacing={3} sx={{ paddingLeft: 5 }}>
          {isDefined(offer) && (
            <>
              <HourlyPayLabel size="large" hourlyPay={offer.attributes.formattedHourlyPay} />
              <TotalPayLabel totalPay={offer.attributes.formattedTotalPay} />
            </>
          )}
        </Stack>

        {isTestAccount && (
          <Button variant="contained" size="large">
            Disabled for test accounts
          </Button>
        )}

        {showMarkInterestButton && isDefined(shiftId) && (
          <MarkInterestButton
            shiftId={shiftId}
            workplaceId={workplace?.id ?? ""}
            offerId={offer?.id}
          />
        )}

        {!shouldHideBookCta && isDefined(shift) && isDefined(offer) && (
          <BookShiftButton
            shiftId={shift.id}
            shiftOfferId={offer.id}
            workplaceId={workplace?.id}
            disabled={!isBookable}
            onBook={() => {
              navigateToModal(SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH, { shiftId: shift.id });
            }}
          />
        )}
      </Stack>

      {isDefined(shift) && isDefined(offer) && isBookable && isRateNegotiationEnabled && (
        <RateNegotiationAction
          rateNegotiationStatus={shift.rateNegotiation?.status}
          rateNegotiationLastOfferBy={shift.rateNegotiation?.lastOfferBy}
          rateNegotiationLastOfferPayRate={shift.rateNegotiation?.lastOfferPayRate}
          shiftId={shift.id}
        />
      )}
    </DialogFooter>
  );
}
