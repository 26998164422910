import { type TimeSlot } from "@clipboard-health/contract-worker-app-bff";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextSkeleton,
} from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { getFormattedTimeRangeWithTimezone } from "@src/appV2/redesign/utils/getFormattedTimeRangeWithTimezone";
import { useOnCallShiftsConfig } from "@src/appV2/Shifts/Shift/useOnCallShiftsConfig";
import { addHours, isAfter } from "date-fns";

import { getTimeSlotLabel } from "../../getTimeSlotLabel";

interface OnCallContentInfoListProps {
  startDate: Date;
  endDate: Date;
  hourlyPay: number | undefined;
  timeSlot: TimeSlot | undefined;
  isLoading: boolean;
  qualificationName: string | undefined;
  workplaceId: string;
  workplaceTimezone: string | undefined;
}

export function OnCallContentInfoList(props: OnCallContentInfoListProps) {
  const {
    hourlyPay,
    startDate,
    endDate,
    timeSlot,
    isLoading,
    qualificationName,
    workplaceTimezone,
    workplaceId,
  } = props;

  const { offersDistanceInMiles, noPlacementBonusPayHours, onCallAreas } = useOnCallShiftsConfig();

  // we want to trim on-call shifts so we only show the first three hours at most
  const maxEndDateToShow = addHours(startDate, 3);
  const { label } = getFormattedTimeRangeWithTimezone({
    dateRange: {
      startDate,
      endDate: isAfter(endDate, maxEndDateToShow) ? maxEndDateToShow : endDate,
    },
    timezone: workplaceTimezone,
    deviceTimezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const onCallArea = (onCallAreas ?? []).find((area) => area.parentWorkplaceId === workplaceId);

  const resolvedMaxDistance = onCallArea?.offersDistanceInMiles ?? offersDistanceInMiles;
  const resolvedNoPlacementBonusPayHours =
    onCallArea?.noPlacementBonusPayHours ?? noPlacementBonusPayHours;

  const noPlacementBonusLabel = formatDollarsAsUsd(
    resolvedNoPlacementBonusPayHours * (hourlyPay ?? 0)
  );

  const timeSlotLabel = timeSlot ? getTimeSlotLabel(timeSlot) : "";

  return (
    <List>
      <ListItem>
        <ListItemIcon iconType="old-phone" />
        <ListItemText>
          <Stack spacing={2}>
            {isLoading ? (
              <TextSkeleton width="60%" />
            ) : (
              <Text variant="h6">On-call from {label}</Text>
            )}
            <Text variant="caption">Stay at home for this window and wait for your placement.</Text>
          </Stack>
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon iconType="car-filled" />
        <ListItemText>
          <Stack spacing={2}>
            {isLoading ? (
              <TextSkeleton width="60%" />
            ) : (
              <Text variant="h6">{timeSlotLabel} shift for ~8 hrs</Text>
            )}
            <Text variant="caption">
              We&apos;ll find you a {qualificationName} shift that is within {resolvedMaxDistance}{" "}
              miles of your address. If we can&apos;t find something for you, get paid{" "}
              {noPlacementBonusLabel}!
            </Text>
          </Stack>
        </ListItemText>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon iconType="map-pin" />
        <ListItemText>
          <Stack spacing={2}>
            <Text variant="h6">Accept your placement</Text>
            <Text variant="caption">
              If you miss or reject your placement, you&apos;ll lose access to on-call shifts.
            </Text>
          </Stack>
        </ListItemText>
      </ListItem>
    </List>
  );
}
