import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { parseISO } from "date-fns";

import { type ShiftType } from "../../constants";
import { type ModalWorkplaceData } from "../../types";
import {
  ShiftBottomSheetBookabilityCard,
  ShiftBottomSheetBookabilityCardsContainer,
} from "../BookabilityCardsContainer";
import { OnCallContentInfoList } from "./ContentInfoList";

interface ShiftBottomSheetSheetContentProps {
  shiftId: string;
  shiftType: ShiftType;
  qualificationName: string;
  workplace: ModalWorkplaceData;
  bookabilityStatus: BookabilityStatusItem;
}

export function OnCallShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const { shiftId, shiftType, qualificationName, workplace, bookabilityStatus } = props;

  const {
    shift,
    isLoadingShiftModalsData,
    hasHoursRestrictionConflict,
    shiftFailedLoading,
    hasMarkedInterest,
    hasBlockingRequirements,
  } = useShiftModalsDataContext();

  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.tertiary, overflowY: "auto" }}>
      <Stack px={5}>
        <ShiftBottomSheetBookabilityCardsContainer
          shiftId={shiftId}
          shiftType={shiftType}
          workplace={workplace}
          bookabilityStatus={bookabilityStatus}
          hasHoursRestrictionConflict={hasHoursRestrictionConflict}
          shiftFailedLoading={shiftFailedLoading}
          hasMarkedInterest={hasMarkedInterest}
          hasBlockingRequirements={hasBlockingRequirements}
          cardsToSkip={[ShiftBottomSheetBookabilityCard.URGENT]}
        />

        <OnCallContentInfoList
          hourlyPay={shift?.offer?.attributes?.hourlyPay}
          startDate={parseISO(shift?.attributes?.start ?? "")}
          endDate={parseISO(shift?.attributes?.end ?? "")}
          timeSlot={shift?.attributes?.timeSlot}
          isLoading={isLoadingShiftModalsData || !isDefined(shift)}
          qualificationName={qualificationName}
          workplaceTimezone={workplace.attributes.location.timezone}
          workplaceId={workplace.id}
        />
      </Stack>
    </Box>
  );
}
