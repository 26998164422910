import { LoadingButton } from "@clipboard-health/ui-react";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import {
  useDeviceGeoLocationIfAllowed,
  useIsDeviceLocationPermissionGranted,
} from "@src/appV2/Location/deviceLocation/geoLocation";
import { SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";

import { PreBookingCheck, useBookShift } from "../../Booking/useBookShift";

interface ConfirmRegisterOnCallShiftButtonProps {
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  onBook: () => void;
}

export function ConfirmRegisterOnCallShiftButton(props: ConfirmRegisterOnCallShiftButtonProps) {
  const { onBook, shiftId, shiftOfferId, workplaceId } = props;

  const { navigateToModal } = useShiftModalsDataContext();

  const { attemptBookingShift, isBooking } = useBookShift({
    shiftId,
    shiftOfferId,
    onBook,
    navigateToModal,
  });

  // We need the device's geolocation, otherwise we can't let the user claim the shift
  const {
    data: isDeviceLocationPermissionGranted,
    isLoading: isDeviceLocationPermissionGrantedLoading,
  } = useIsDeviceLocationPermissionGranted();

  const geolocationIsUnavailable = !isDeviceLocationPermissionGranted && isCapacitorPlatform();

  // if the query is disabled, 'isLoading' is true until the query runs and we have data
  // this can cause an infinite spinner if location permissions are not given, as
  // useDeviceGeoLocationIfAllowed will always be isLoading === true. Therefore,
  // we need to use isInitialLoading instead
  // https://tanstack.com/query/v4/docs/framework/react/guides/disabling-queries#isinitialloading

  const { data: deviceGeolocation, isInitialLoading: isDeviceGeolocationLoading } =
    useDeviceGeoLocationIfAllowed({
      enabled: !geolocationIsUnavailable,
    });

  const isLoading = isDeviceLocationPermissionGrantedLoading || isDeviceGeolocationLoading;

  return (
    <LoadingButton
      size="large"
      variant="contained"
      isLoading={isLoading || isBooking}
      onClick={async () => {
        if (geolocationIsUnavailable) {
          navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
          return;
        }

        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_CONFIRM_REGISTER_ON_CALL_SHIFT, {
          shiftId,
          workplaceId,
          shiftOfferId,
        });

        await attemptBookingShift({
          geoLocation: deviceGeolocation?.geoLocation,
          // we want to skip this check which shows a booking confirmation modal, because we have a different modal for on call shifts
          skipChecks: [PreBookingCheck.BOOKING_CONFIRMATION],
        });
      }}
    >
      Confirm booking
    </LoadingButton>
  );
}
